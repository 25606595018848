export const validateUppercase = (value, constraint) => {

    if(value.toUpperCase() === value) return { status: true }

    return { status: false, key: "validation.uppercase" }

}
export const validateClearingNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        console.log(rule)
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }

    return { status: false, key: `validation.clearingnumber.${message}` }    
}
export const validateAccountNumber = (value, constraint) => {
    if(!value) return {status: true}
    let parts = constraint ? constraint.split("~") : []
    let rule = parts.length > 1 ? parts[1] : null
    let message = parts[0]
    if(rule){
        console.log(rule)
        let regex = new RegExp(rule)
        if(regex.exec(value)){
            return {status: true}
        }
    }
    return { status: false, key: `validation.accountnumber.${message}` }    
}
export const validateTime = (value, constraint) => {
    if(!value) return {status: true}
    var hour = 0
    var minute = 0
    let parts = constraint ? constraint.split("~") : []
    if (parts.length == 1) {
        hour = parts[0]
    }else if (parts.length == 2) {
        hour = parts[0]
        minute = parts[1]
    }
    if (hour>24 || hour<0) {
        return { status: false, key: `validation.invalidTime` } 
    }
    if (minute>60 || minute <0) {
        return { status: false, key: `validation.invalidTime` } 
    }
    return {status: true}
}