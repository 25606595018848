import axios from "axios";
import moment from "moment";
import { auth, envelopes, bisnode } from "@verified/libvf";
import { Promise } from "core-js";

export default {
  name: "app",
  data() {
    return {
      required: true,
      step: 1,
      lang: "sv_SE",
      loading: true,
      pdfUploaded: true,
      protocol: false,
      config: null,
      
      ud: {
        _isForwarded: true,
        fileName: null,
        translations: null,
        lang: "sv_SE",
        customer: null,
        orgNo: null,
        guarantor: null,
        orgNoOrSsn: null,
        requestOrContractNum: null,

        recipients: [
          {
            firstName: "",
            lastName: "",
            email: ""
          }
        ],
        files: null,
        redirectTo: "https://www.grenkeleasing.se/se.html"
      }
    };
  },
  methods: {
    handleFileChange(e) {
      // console.log('file ==', e.target.files[0])
      this.ud.files = e.target.files[0];
      this.pdfUploaded = true;
			this.$emit('input', e.target.files[0])
    },
    removeFile() {
      this.pdfUploaded = false;
      this.ud.files = null;
    },
    validateForm(form) {
      return this.$refs[form].validate();
    },

    addSigner() {
      this.ud.recipients.push({
        firstName: "",
        lastName: "",
        email: ""
      });
    },
    async removeRecipients(){
			console.log(this.envelope)
			let promises = []
			if(this.envelope.data.recipients){
				this.envelope.data.recipients.forEach(function(recipient) {
					promises.push(axios.delete(`/api${recipient.uid}`,{
						headers:{
							'authorization' : `JWT ${auth.token}`,
							'x-namespace': auth.namespace
						}
					}))
				}, this);
			}
			return Promise.all(promises)
		},
    async addRecipients() {
      let order;
      const promises = this.ud.recipients.map(async (receipient, i) => {
        order = i + 1;
        await this.envelope.addRecipient({
          email: receipient.email,
          givenName: receipient.firstName,
          familyName: receipient.lastName,
          order,
          signingMethod: "bankid-se",
          language: this.ud.lang,
          role: {
            action: "sign",
            label: "Signera",
            name: "signer"
          }
        });
      });
      return Promise.all(promises).then(
        this.envelope.addRecipient({
          familyName: "AB",
          givenName: "Grenkeleasing",
          email: "sales@grenke.se",
          order: order + 1,
          signingMethod: "bankid-se",
          language: this.ud.lang,
          role: {
            action: "sign",
            label: "signera som Grenke",
            name: "grenke-signer"
          }
        })
      );
    },
    async submitData() {
      let self = this;

      if (!this.validateForm("form1") || !this.ud.files) {
        this.pdfUploaded = false;
        console.log("return");
        return;
      }
      this.loading = true;

      self.ud.fileName = `Borgensåtagande - ${this.ud.requestOrContractNum}.pdf`;
      console.log( self.ud.fileName);
      try {
        await self.removeRecipients()
        await self.envelope.reflect();
        await self.envelope.firstTemplate().setUserData(self.ud)
        await self.envelope.firstDocument().put({ name: this.ud.fileName });

        if(self.ud.confirmationEmail)
        {
          let metadata = {
            silentParties: [
              {
                givenName: "Enbart",
                familyName: "Borgen",
                email: self.ud.confirmationEmail
              }
            ]
          };
          await  self.envelope.put({ metadata: metadata })
        }
        
        
        await self.envelope.reflect();
        await self.envelope.firstDocument().uploadAttachment(self.ud.files.name, self.ud.files);
        await self.addRecipients();
        console.log(this.envelope.documents);
        
        setTimeout(() => window.close(), 3000);

      }
      catch(e) {
        console.log(e)
        this.loading = false
        throw e;
        
      }
    }
  },
  computed: {},
  async created() {
    this.setIso(this.ud.lang);

    const [translations] = await Promise.all([
      axios.get(
        "https://sheets.web-services.verified.eu/grenke-borgen/translations"
      )
    ]);
    this.ud.translations = translations.data;
    this.mergeLocale(translations.data);
    try {
      await auth.useTokenFromUrl()
      this.envelope = await envelopes.getFromUrl()
      console.log("envelop =",this.envelope)
      this.loading = false
    }
    catch (e) {
      this.loading = false
      this.error = true
      this.errorText = 'noToken'
      console.log(e)
    }
  }
};
