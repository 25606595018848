<template>
        <ve-form-group >
                <ve-label :label="label" :class="required ? 'required':''"></ve-label>
                <input ref="field" @keydown="handleInput" :value="value" v-mask="mask" :type="type" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" class="form-control" :autocomplete="autocomplete">
                <div v-if="!validation.status" class="invalid-feedback">{{ __(validation.key, validation.args) }}</div>
         </ve-form-group>
</template>

<script>
// import { validate } from "../../validations"

export default {
    name: "ve-price-input",
    props: {
        required: {
            type: Boolean,
            default: false
        },
        value: [String, Number],
        type: String,
        rules: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        mask: {
            type: null,
            default: ""
        },
        dictionary: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            validation: { status: true }
        }
    },
    methods: {
        handleInput(e) {
            // console.log('input=',e)
            // console.log('input=',e.srcElement.value)
            // var value = e.data
            var char = e.key
            var currentVal = e.srcElement.value
            if(!/^[0-9,\s]$/.test(char)){
                // console.log("if not number");
                if (/^[a-zA-Z,\s]$/.test(char)) {
                    // console.log("if letters");
                    e.preventDefault();
                }else if (/^[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\?\/\\]+$/.test(char)) {
                    // console.log("if charecters");
                    e.preventDefault();
                }
                 if(e.keyCode==8)
                    e.preventDefault();
                 else
                    return;
            }else{
                e.preventDefault();
            }
            if(char === ',' && currentVal.indexOf(',') !== -1){
                // console.log('11111')
                return;
            }
            //Disallow space at the beginning or after a space
            if(char === ' ' && (currentVal.length == 0 || currentVal.slice(-1) === ' ')){
                // console.log('22222')
                return;
            }
            //Disallow more than 2 decimals
            if(/^[0-9]$/.test(char) && currentVal.indexOf(',') !== -1 && currentVal.slice(-3).slice(0,1) === ','){
                // console.log('33333')
                return;
            }
            var newVal = currentVal.replace(/\s/g, '');
            if(e.keyCode !== 8){
                    newVal += char;
            }else{
                newVal = newVal.slice(0, -1);
            }
            // console.log("newval=",newVal)
            var decimalPart = '';
                var valuePart = newVal;
                if(newVal.indexOf(',') !== -1){
                    decimalPart = ',' + newVal.substring(newVal.indexOf(',')+1);
                    valuePart = newVal.substring(0, newVal.indexOf(','));
                }
                var val='';
                while(valuePart!=''){
                    if(valuePart.length>3){
                    val = ' '+valuePart.slice(valuePart.length-3, valuePart.length) +val;
                    valuePart = valuePart.slice(0,valuePart.length-3);
                    }else{
                    val = ' '+valuePart +val;
                    valuePart = '';
                    }
                }
                var result = val.trim();
                result = result+decimalPart;
            // console.log("result =",result)
            e.srcElement.value = result;
            
            this.performValidation(e.srcElement.value)

            this.$emit('input', e.srcElement.value)

            if(this.dictionary){
                if(this.dictionary[e.srcElement.value]){
                    this.$emit('on-dictionary-match', this.dictionary[e.srcElement.value])
                }
            }

        },

        performValidation(value) {
            
            // this.validation = validate(value, this.rules)
            // alert(this.required)
            if(this.required){
                if(value && value.length > 0) {
                    this.validation =  { status: true }
                }
                else {
                    this.validation =  {
                        status: false,
                        key: "validation.required"
                    }
                }
            }else{
                if(value && value.length > 0) {
                    this.validation =  { status: true }
                }
            }
            

            if(this.validation.status) {
                this.$refs.field.setCustomValidity("") 
            }
            else {
                this.$refs.field.setCustomValidity(this.validation.message)
            }
                
        },

    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.performValidation(val)
            }
        }
    },
    mounted() {
        this.performValidation(this.value)
    }
}
</script>

<style>

</style>
