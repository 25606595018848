import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'
import { validateUppercase} from './validations'
import { validateClearingNumber} from './validations'
import { validateAccountNumber} from './validations'
import { validateTime} from './validations'
import Stepper from './components/stepper'
import Checkbox from './components/checkbox'
import Input from './components/input'

import Datepicker from 'vuejs-datepicker';

Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('uppercase', validateUppercase)
veform.addCustomValidation('clearing-number', validateClearingNumber)
veform.addCustomValidation('account-number', validateAccountNumber)
veform.addCustomValidation('time', validateTime)

Vue.use(veform)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.use(Input)
Vue.component('Datepicker',Datepicker)

new Vue({
    render: h => h(App),
}).$mount('#app')